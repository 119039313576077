<template>
  <div>
    <div class="pb-2">
      <h3>Discount Class Management</h3>
      <hr />
    </div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.discountClassForm"
    />
    <div class="card" v-if="discount">
      <div class="card-header">
        <div class="card-title mb-0">Discount Class Management</div>
      </div>
      <div class="card-body">
        <ValidationObserver ref="discountClassForm">
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <TextInput
                  disabled
                  name="Customer Class Id"
                  id="discountReference"
                  v-model="discount.discountReference"
                />
                <small class="form-text text-muted mb-2"
                  >Reference ID for the discount class</small
                >
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="accountingCodeSuffix|max:10"
                  name="Accounting Code Suffix"
                  id="accountingCode"
                  v-model="discount.accountingCodeSuffix"
                />
                <small class="form-text text-muted mb-2">
                  Accounting code suffix to be used for the discount class of
                  pricing season
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="required|max:100"
                  name="UI Discount Class Name"
                  id="uiDiscountClassName"
                  placeholder="Enter discount class name"
                  v-model="discount.name"
                />
                <small class="form-text text-muted mb-2"
                  >Discount class name to be displayed at checkout</small
                >
              </div>
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required"
                  name="Residency"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="residency"
                  >
                    Residency
                    <span class="error">*</span>
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="residency"
                    v-model="discount.residencyId"
                    v-bind="ariaInput"
                  >
                    <option
                      v-for="(res, index) in residencies"
                      :key="index"
                      :value="res.id"
                      aria-placeholder="Select Residency..."
                      >{{ res.name }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2"
                  >Residency of discount class</small
                >
              </div>
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  :rules="verificationRule"
                  name="Verification Required"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="verificationRequired"
                  >
                    Verification Required
                    <span v-show="!verificationDisabled" class="error">*</span>
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="verificationRequired"
                    v-model="discount.verificationRequired"
                    v-bind="ariaInput"
                    :disabled="verificationDisabled"
                  >
                    <option
                      v-for="(ver, index) in verifications"
                      :key="index"
                      :value="ver.value"
                      aria-placeholder="Select Verification Required..."
                      >{{ ver.name }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2">
                  Determines if customers zip code will be verified for
                  residency discount
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="required|between:1,99"
                  name="Priority"
                  id="priority"
                  placeholder="Enter discount class priority"
                  v-model="discount.priority"
                />
                <small class="form-text text-muted mb-2">
                  Discount class priority between 1-99 inclusive, highest number
                  priority will be applied as discount if multiple apply
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  rules="required|max:800"
                  name="UI Discount Class Description"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="uiDiscountClassDescription"
                  >
                    UI Discount Class Description
                    <span class="error">*</span>
                  </label>
                  <ckeditor
                    :config="editorConfig"
                    v-model="discount.description"
                    v-bind="ariaInput"
                  />
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2"
                  >Discount class description to be displayed at checkout</small
                >
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  rules="max:800"
                  name="Internal Class Description"
                  id="internalDiscountClassDescription"
                  placeholder="Enter internal discount class description"
                  v-model="discount.internalDescription"
                  rows="4"
                />
                <small class="form-text text-muted mb-2"
                  >Discount class description to be displayed at checkout</small
                >
              </div>
            </div>
            <button type="submit" class="btn btn-lg btn-primary mt-4 mr-2">
              Save
            </button>
            <button
              class="btn btn-lg btn-secondary mt-4 mr-2"
              @click="cancel"
              type="button"
            >
              Cancel
            </button>
            <p class="mt-2">
              <span style="color: red;">*</span> Indicates a required field
            </p>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div class="card" v-else>
      <div class="card-header">
        <div class="card-title mb-0">Discount Classes</div>
      </div>
      <div class="card-body">
        <b-table
          ref="table"
          striped
          :fields="fields"
          :items="discountClasses"
          stacked="md"
          small
          class="mt-4"
          bordered
          sort-icon-left
        >
          <template v-slot:cell(residencyId)="data">{{
            getResidencyName(data.item.residencyId)
          }}</template>
          <template v-slot:cell(verificationRequired)="data">{{
            data.item.verificationRequired ? "Yes" : "No"
          }}</template>

          <template v-slot:cell(id)="data">
            <button
              class="btn btn-primary btn-sm mx-1"
              @click="editClass(data.item.id)"
            >
              Modify
            </button>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import AdminPricingManagementService from "@/services/admin/AdminPricingManagementService.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import ckconfig from "@/helpers/ckeditorConfig.js";

export default {
  name: "AdminDiscountClassManagement",
  title: "Admin - Discount Class Management",
  components: {
    ValidationObserver,
    ValidationProvider,
    TextInput,
    FormErrorAlert
  },
  data() {
    return {
      errors: [],
      discount: null,
      discountClasses: [],
      residencies: [],
      verifications: [
        { name: "Yes", value: true },
        { name: "No", value: false }
      ],
      fields: [
        {
          key: "discountReference",
          sortable: true,
          label: "Customer Class Id"
        },
        { key: "name", sortable: true, label: "UI Discount Class Name" },
        { key: "residencyId", sortable: true, label: "Residency" },
        {
          key: "verificationRequired",
          sortable: true,
          label: "Verification Required"
        },
        { key: "priority", sortable: true },
        { key: "id", label: "Actions/Options" }
      ],
      editorConfig: ckconfig
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    verificationRule() {
      if (
        this.discount.residencyId &&
        this.discount.residencyId === this.inStateId
      ) {
        return "required";
      } else {
        return "";
      }
    },
    verificationDisabled() {
      if (
        this.discount.residencyId &&
        this.discount.residencyId === this.inStateId
      ) {
        return false;
      } else {
        return true;
      }
    },
    inStateId() {
      return this.residencies.find(x => x.name === "In State")?.id;
    }
  },
  methods: {
    cancel() {
      this.discount = null;
    },
    async getResidencyOptions() {
      const service = new AdminLookupService(this.tenantId);
      const options = await service.getResidencyOptions();
      this.residencies = options;
    },
    async getDiscountClasses() {
      const service = new AdminPricingManagementService(this.tenantId);
      const classes = await service.getDiscountClasses();
      classes.forEach(x => {
        x.discountReference = "Discount Class - " + x.id;
      });
      this.discountClasses = classes;
    },
    getResidencyName(id) {
      return this.residencies.find(x => x.id === id)?.name;
    },
    editClass(id) {
      const discount = this.discountClasses.find(x => x.id === id);
      this.discount = { ...discount };
    },
    priorityCheck() {
      const otherDiscounts = this.discountClasses.filter(
        x => x.id !== this.discount.id
      );
      const discount = otherDiscounts.find(
        x => x.priority === +this.discount.priority
      );
      return !!discount;
    },
    async onSubmit() {
      if (this.priorityCheck()) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message:
            "A discount already exists with the priority of " +
            this.discount.priority,
          layer: "admin"
        });
        return;
      }
      this.$refs.discountClassForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.discountClassForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.discountClassForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.$store.commit("auth/setLoading", true);
            const service = new AdminPricingManagementService(this.tenantId);
            const response = await service.updateDiscountClass(this.discount);
            if (response?.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: "Discount Class has been updated.",
                layer: "admin"
              });
              this.getDiscountClasses();
              this.cancel();
            } else {
              this.loading = false;
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    }
  },
  created() {
    this.getResidencyOptions();
    this.getDiscountClasses();
  }
};
</script>
